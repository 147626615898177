import './scrolltable.scss';
class Scrolltable {
    constructor (element, options) {
        const defaults = {
            offset: 4,
            scrollNavigation: true
        };

        this.settings = Object.assign({}, defaults, options);

        this.$table = element;
        this.scrollable = false;
        this.scrollStart = true;
        this.scrollEnd = false;
        this.currentColumn = 0;

        window.setTimeout(() => {
            this.initialize();
        }, 150);
    }

    initialize () {
        const $scroller = document.createElement('div');
        const $scrollerInner = document.createElement('div');
        const $scrollerWrapper = document.createElement('div');
        const $scrollerTable = document.createElement('div');
        let $scrollControls = null;
        let $scrollPagination = null;
        let $paginationBullet = null;
        const $caption = this.$table.querySelector('caption');
        let $prevButton = null;
        let $nextButton = null;

        const $firstTableRow = this.$table.querySelectorAll('tr')[0];
        const $firstTableColumns = $firstTableRow.querySelectorAll('th, td');

        const buildScrollControls = (callback) => {
            $scrollControls = document.createElement('div');
            $scrollControls.classList.add('scrolltable__controls');

            $scrollPagination = document.createElement('div');
            $scrollPagination.classList.add('scrolltable__pagination');

            $paginationBullet = document.createElement('span');
            $paginationBullet.classList.add('scrolltable__pagination-bullet');
            $paginationBullet.setAttribute('role', 'button');
            $scrollPagination.appendChild($paginationBullet);

            $scrollControls.appendChild($scrollPagination);

            $prevButton = document.createElement('span');
            $prevButton.classList.add('scrolltable__prev');

            $nextButton = document.createElement('span');
            $nextButton.classList.add('scrolltable__next');

            $scrollControls.appendChild($prevButton);
            $scrollControls.appendChild($nextButton);

            if (typeof callback === 'function') {
                callback();
            }
        };

        const buildWrapper = (callback) => {
            $scroller.classList.add('scrolltable__scroller');
            this.$table.parentNode.insertBefore($scroller, this.$table);
            $scroller.appendChild(this.$table);

            $scrollerWrapper.classList.add('scrolltable__scrollerwrapper');
            $scroller.parentNode.insertBefore($scrollerWrapper, $scroller);
            $scrollerWrapper.appendChild($scroller);

            $scrollerInner.classList.add('scrolltable__inner');
            $scrollerWrapper.parentNode.insertBefore($scrollerInner, $scrollerWrapper);
            $scrollerInner.appendChild($scrollerWrapper);

            $scrollerTable.classList.add('scrolltable');
            $scrollerInner.parentNode.insertBefore($scrollerTable, $scrollerInner);
            $scrollerTable.appendChild($scrollerInner);

            if (typeof callback === 'function') {
                callback();
            }
        };

        const checkScrollable = (callback) => {
            if ($scrollerTable.offsetWidth + this.settings.offset < $scroller.offsetWidth) {
                $scrollerTable.classList.add('has--scroll');
                this.scrollable = true;

                if ($scrollControls === null && this.settings.scrollNavigation === true) {
                    buildScrollControls(callback);

                    $scrollerTable.appendChild($scrollControls);
                }
            } else {
                this.scrollable = false;

                if ($scrollControls !== null) {
                    $scrollControls.parentNode.removeChild($scrollControls);
                    $scrollControls = null;
                    $scrollPagination = null;
                    $paginationBullet = null;
                    $prevButton = null;
                    $nextButton = null;
                }
            }
        };

        const moveTargets = (scrollLeft) => {
            const scrollDistance = Math.round($scroller.offsetWidth - $scrollerTable.offsetWidth - 4);

            let percentage = Math.round(100 / scrollDistance * scrollLeft);

            if (percentage >= 100) {
                percentage = 100;
                this.scrollEnd = true;
                this.scrollStart = false;
            } else if (percentage === 0) {
                this.scrollStart = true;
                this.scrollEnd = false;
            } else {
                this.scrollStart = false;
                this.scrollEnd = false;
            }

            const moveDistance = Math.round($scrollPagination.offsetWidth - $paginationBullet.offsetWidth);
            const moveStep = Math.round(moveDistance / 100 * percentage);

            $paginationBullet.style.transform = 'translateX(' + moveStep + 'px)';

            if ($caption) {
                $caption.style.transform = 'translateX(' + scrollLeft + 'px)';
            }

            checkButtons();
        };

        const checkButtons = () => {
            if (this.scrollStart === true) {
                $prevButton.classList.add('is--inactive');
            } else {
                $prevButton.classList.remove('is--inactive');
            }

            if (this.scrollEnd === true) {
                $nextButton.classList.add('is--inactive');
            } else {
                $nextButton.classList.remove('is--inactive');
            }
        };

        buildWrapper(() => {
            checkScrollable(() => {
                checkButtons();
            });
        });

        if (this.settings.scrollNavigation === true && this.scrollable === true) {
            $scrollerWrapper.addEventListener('scroll', () => {
                moveTargets($scrollerWrapper.scrollLeft);
            });
        }

        window.addEventListener('resize', () => {
            checkScrollable();
            if (this.settings.scrollNavigation === true && this.scrollable === true) {
                moveTargets($scrollerWrapper.scrollLeft);
            }
        });

        window.addEventListener('orientationchange', () => {
            checkScrollable();
            if (this.settings.scrollNavigation === true && this.scrollable === true) {
                moveTargets($scrollerWrapper.scrollLeft);
            }
        });

        if ($prevButton !== null) {
            $prevButton.addEventListener('click', () => {
                if (this.scrollStart === false) {
                    if (this.currentColumn > 0) {
                        this.currentColumn = this.currentColumn - 1;
                    }
                    $scrollerWrapper.scrollLeft = $firstTableColumns[this.currentColumn].offsetLeft - this.$table.offsetLeft;
                }
            });
        }

        if ($nextButton !== null) {
            $nextButton.addEventListener('click', () => {
                if (this.scrollEnd === false) {
                    if (this.currentColumn < $firstTableColumns.length) {
                        this.currentColumn = this.currentColumn + 1;
                    }
                    $scrollerWrapper.scrollLeft = $firstTableColumns[this.currentColumn].offsetLeft - this.$table.offsetLeft;
                }
            });
        }
    }
}

export { Scrolltable };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $tables = $context.querySelectorAll('.rte table');

        if ($tables.length > 0) {
            for (let i = 0; i < $tables.length; i++) {
                const $table = $tables[i];
                $table.API = new Scrolltable($tables[i]);
            }
        }
    }
});
